<template>
  <div class="submit-form">
    <div v-if="!submitted">
      <h5>Firmenname</h5>
      <div class="form-group">
        <label for="mdts">Mandantennummer Steuerberater<br>(MDTS, fünfstellig)</label>
        <input type="text"
               class="form-control"
               id="mdts"
               required
               v-model="mandant.mdts"
               name="mdts"
        />
      </div>
      <div class="form-group">
        <label for="firmenbezeichnung">Firmenbezeichnung Voll</label>
        <input type="text"
               class="form-control"
               id="firmenbezeichnung"
               required
               v-model="mandant.firmenbezeichnung.voll"
               name="firmenbezeichnung"
        />
      </div>
      <div class="form-group">
        <label for="firmenbezeichnungkurz">Firmenbezeichnung Kurz <br>(für die Anzeige in Programmoberflächen)</label>
        <input type="text"
               class="form-control"
               id="firmenbezeichnungkurz"
               required
               v-model="mandant.firmenbezeichnung.kurz"
               name="firmenbezeichnungkurz"
        />
      </div>
      <div class="form-group">
        <label for="fnzusatz">Firmenzusatz</label>
        <input type="text"
               class="form-control"
               id="fnzusatz"
               required
               v-model="mandant.firmenbezeichnung.zusatz"
               name="fnzusatz"
        />
      </div>
      <h5 class="mt-5">Inhaber</h5>
      <div class="form-group">
        <label for="inhabertitel">Titel</label>
        <input type="text"
               class="form-control"
               id="inhabertitel"
               required
               v-model="mandant.inhaber.titel"
               name="inhabertitel"
        />
      </div>
      <div class="form-group">
        <label for="inhabervorname">Vorname</label>
        <input type="text"
               class="form-control"
               id="inhabervorname"
               required
               v-model="mandant.inhaber.vorname"
               name="inhabervorname"
        />
      </div>
      <div class="form-group">
        <label for="inhabername">Nachname</label>
        <input type="text"
               class="form-control"
               id="inhabername"
               required
               v-model="mandant.inhaber.name"
               name="inhabername"
        />
      </div>
      <div class="form-group">
        <label for="gfuehrertitel">Geschäftsführer Titel</label>
        <input type="text"
               class="form-control"
               id="gfuehrertitel"
               required
               v-model="mandant.gFuehrer.titel"
               name="gfuehrertitel"
        />
      </div>
      <div class="form-group">
        <label for="gfuehrervorname">Geschäftsführer Vorname</label>
        <input type="text"
               class="form-control"
               id="gfuehrervorname"
               required
               v-model="mandant.gFuehrer.vorname"
               name="gfuehrervorname"
        />
      </div>
      <div class="form-group">
        <label for="gfuehrername">Geschäftsführer Nachname</label>
        <input type="text"
               class="form-control"
               id="gfuehrername"
               required
               v-model="mandant.gFuehrer.name"
               name="gfuehrername"
        />
      </div>
      <h5 class="mt-5">Adresse</h5>
      <div class="form-group">
        <label for="strasse">Straße</label>
        <input type="text"
               class="form-control"
               id="strasse"
               required
               v-model="mandant.adresse.strasse"
               name="strasse"
        />
      </div>
      <div class="form-group">
        <label for="plz">Postleitzahl</label>
        <input type="text"
               class="form-control"
               id="plz"
               required
               v-model="mandant.adresse.plz"
               name="plz"
        />
      </div>
      <div class="form-group">
        <label for="ort">Ort</label>
        <input type="text"
               class="form-control"
               id="ort"
               required
               v-model="mandant.adresse.ort"
               name="ort"
        />
      </div>
      <div class="form-group">
        <label for="web">Internetseite (z.B.: http://website.de)</label>
        <input type="text"
               class="form-control"
               id="web"
               required
               v-model="mandant.kontakt.web"
               name="web"
        />
      </div>
      <div class="form-group">
        <label for="telefon">Telefon</label>
        <input type="text"
               class="form-control"
               id="telefon"
               required
               v-model="mandant.kontakt.telefon"
               name="telefon"
        />
      </div>
      <div class="form-group">
        <label for="telefon">Fax</label>
        <input type="text"
               class="form-control"
               id="fax"
               required
               v-model="mandant.kontakt.fax"
               name="fax"
        />
      </div>
      <div class="form-group">
        <label for="email">E-Mail Adresse</label>
        <input type="text"
               class="form-control"
               id="email"
               required
               v-model="mandant.kontakt.email"
               name="email"
        />
      </div>


      <button @click="saveMandant" class="btn btn-success">Speichern</button>
    </div>

    <div v-else>
      <h4>Mandant erfolgreich gespeichert!</h4>
      <button class="btn btn-success" @click="newMandant">Weiteren hinzufügen</button>
    </div>

    <div v-if="error">
      <p class="text-danger">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import MandantService from "../services/mandant.service";

export default {
  name: "add-mandant",
  data() {
    return {
      mandant: {
        mdts: "",
        firmenbezeichnung: {
          voll: "",
          kurz: "",
          zusatz: "",
        },
        inhaber: {
          titel: "",
          vorname: "",
          name: "",
        },
        gFuehrer: {
          titel: "",
          vorname: "",
          name: "",
        },
        kontakt: {
          fax: "",
          telefon: "",
          email: "",
          web: "",
        },
        adresse: {
          strasse: "",
          ort: "",
          plz: "",
        },
      },
      error: null,
      submitted: false
    };
  },
  methods: {
    saveMandant() {
      let data = this.mandant;

      MandantService.create(data)
          .then(response => {
            this.mandant._id = response.data._id;
            console.log(response.data);
            this.submitted = true;
          })
          .catch((error => this.error = error.response.data.message));
    },

    newMandant() {
      this.submitted = false;
      this.error = false;
    }
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>